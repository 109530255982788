.overlay-parent {
	position: relative;

	.box {
		position: absolute;
		// margin: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	.overlay {
		z-index: 10;
		// background: white; opacity: 0.2;
		// background: rgba(255, 255, 255, 0.15);

		svg {
			position: absolute;
			// margin: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			fill: red;
		}
	}
}
